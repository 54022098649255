/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-amber-50 text-amber-900;
  }
}

@layer components {
  .card-flip {
    perspective: 1000px;
    transform-style: preserve-3d;
  }
  
  .card-flip-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .card-flip.flipped .card-flip-inner {
    transform: rotateY(180deg);
  }
  
  .card-front,
  .card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }
  
  .card-back {
    transform: rotateY(180deg);
  }
}

.progress-bar {
  transition: width 0.3s ease;
}